
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
.root {
  margin: hz-spacing(xxxl) 0;
  text-align: center;
}

.message {
  @include hz-textStyle(body-large);
  margin: hz-spacing(xs) 0 hz-spacing(m);
}
