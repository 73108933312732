
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.root {
  display: none;
  padding: hz-spacing(xs) 0;

  @include hz-breakpoint-up(m) {
    display: block;
  }
}

.header {
  margin-bottom: hz-spacing(xs);
}

.link {
  display: flex;

  &:not(:last-child) {
    margin-bottom: hz-spacing(s);
  }
}

.icon {
  display: block;
}

.title {
  display: block;
}

.text {
  color: hz-color(text-primary);
  display: block;
}

.title_text {
  display: block;
  padding-left: hz-spacing(xxxs);
}
