
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@keyframes shineLines {
  0% {
    background-position: -150px 0;
  }

  50%,
  100% {
    background-position: calc(100% + 150px) 0;
  }
}

/* stylelint-disable-next-line color-no-hex */
$base-color: #e5e5e4;
/* stylelint-disable-next-line color-no-hex */
$shine-color: #eaeaea;
$animation-duration: 1.5s;

.base {
  height: 100%;
  position: relative;

  &::before {
    background-color: $base-color;
    background-repeat: no-repeat;
    background-size: 150px;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.withAnimation {
  &::before {
    animation: shineLines $animation-duration infinite linear;
    background-image: linear-gradient(to right, transparent 0%, $shine-color 50%, $base-color 100%);
  }
}

.text {
  display: inline-block;
  width: 100%;

  &.short {
    max-width: 100px;
  }

  &.regular {
    max-width: 200px;
  }

  &.long {
    max-width: 300px;
  }

  &::before {
    border-radius: 4px;
  }
}

.noShadow {
  box-shadow: none !important;
}

.border {
  border: 1px solid $shine-color;
}
