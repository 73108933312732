
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
.message {
  margin: hz-spacing(xxxl) 0;
  text-align: center;
}

.loadmore {
  margin: hz-spacing(m) 0 0;
  text-align: center;
}
