
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.bannerBreakout {
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
}

.bannerFeedItem {
  overflow: hidden;
}

.bannerTopContainer {
  align-items: center;
  display: flex;
  flex: 1 0 100%;
  justify-content: center;
  margin-bottom: hz-spacing(m);
  min-height: 250px;

  @include hz-breakpoint-up(m) {
    min-height: 106px;
  }

  // minimum viewPort size for banners in feeds
  @media (min-width: 1320px) {
    display: none;
  }
}
